import { Popover, Tab, Transition } from "@headlessui/react";
import {
  BanknotesIcon,
  GlobeAltIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { FC, Fragment, useEffect, useState } from "react";
import { headerCurrency } from "./CurrencyDropdown";
import { parseCookies, setCookie, destroyCookie } from 'nookies';
import { useRouter, usePathname } from 'next/navigation';

const COOKIE_NAME = 'googtrans';
const MAX_COOKIE_AGE = 2147483647; // Maximum safe value in seconds (~68 years)

interface Language {
  id: string;
  name: string;
  emoji: string;
  description: string;
  href: string;
  active?: boolean;
  languageCode: string;
}

export const headerLanguage: Language[] = [
  {
    id: "French",
    name: "Français",
    emoji: "🇫🇷",
    description: "France",
    href: "##",
    active: true,
    languageCode: "fr"
  },
  {
    id: "English",
    name: "English",
    emoji: "🇺🇸",
    description: "United States",
    href: "##",
    languageCode: "en"
  },
  {
    id: "German",
    name: "Deutsch",
    emoji: "🇩🇪",
    description: "Germany",
    href: "##",
    languageCode: "de"
  },
  {
    id: "Spanish",
    name: "Español",
    emoji: "🇪🇸",
    description: "Spain",
    href: "##",
    languageCode: "es"
  },
  {
    id: "Chinese",
    name: "中文",
    emoji: "🇨🇳",
    description: "China",
    href: "##",
    languageCode: "zh"
  },
  {
    id: "Hindi",
    name: "हिन्दी",
    emoji: "🇮🇳",
    description: "India",
    href: "##",
    languageCode: "hi"
  },
  {
    id: "Arabic",
    name: "العربية",
    emoji: "🇸🇦",
    description: "Arabic",
    href: "##",
    languageCode: "ar"
  },
  {
    id: "Bengali",
    name: "বাংলা",
    emoji: "🇧🇩",
    description: "Bangladesh",
    href: "##",
    languageCode: "bn"
  },
  {
    id: "Portuguese",
    name: "Português",
    emoji: "🇵🇹",
    description: "Portugal",
    href: "##",
    languageCode: "pt"
  },
  {
    id: "Russian",
    name: "Русский",
    emoji: "🇷🇺",
    description: "Russia",
    href: "##",
    languageCode: "ru"
  },
  {
    id: "Japanese",
    name: "日本語",
    emoji: "🇯🇵",
    description: "Japan",
    href: "##",
    languageCode: "ja"
  },
  {
    id: "Korean",
    name: "한국어",
    emoji: "🇰🇷",
    description: "Korea",
    href: "##",
    languageCode: "ko"
  }
];

interface LangDropdownProps {
  panelClassName?: string;
  className?: string;
}

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

// Utility function to clean up duplicate cookies
const cleanupDuplicateCookies = () => {
  const domain = window.location.hostname;
  const mainDomain = domain.split('.').slice(-2).join('.');
  
  // Remove cookies at current domain level
  destroyCookie(null, COOKIE_NAME, { path: '/' });
  destroyCookie(null, COOKIE_NAME, { path: '/', domain });
  
  // Remove cookies at root domain level
  destroyCookie(null, COOKIE_NAME, { 
    path: '/',
    domain: `.${mainDomain}`
  });
};

// Set permanent language cookie across domains
const setLanguageCookie = (lang: string) => {
  const value = `/auto/${lang}`;
  const domain = window.location.hostname;
  const mainDomain = domain.split('.').slice(-2).join('.');
  
  // Clean up existing cookies first
  cleanupDuplicateCookies();
  
  // Set cookie at current domain level
  setCookie(null, COOKIE_NAME, value, {
    path: '/',
    maxAge: MAX_COOKIE_AGE,
    secure: true,
    sameSite: 'strict'
  });
  
  // Set cookie at root domain level for Google Translate compatibility
  setCookie(null, COOKIE_NAME, value, {
    path: '/',
    domain: `.${mainDomain}`,
    maxAge: MAX_COOKIE_AGE,
    secure: true,
    sameSite: 'strict'
  });
};

const LangDropdown: FC<LangDropdownProps> = ({
  panelClassName = "top-full right-0 max-w-sm w-96",
  className = "hidden md:flex",
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const [currentLanguage, setCurrentLanguage] = useState<string>();
  const [isTranslating, setIsTranslating] = useState(false);

  useEffect(() => {
    const cookies = parseCookies();
    const existingLanguageCookieValue = cookies[COOKIE_NAME];

    if (existingLanguageCookieValue) {
      const sp = existingLanguageCookieValue.split('/');
      if (sp.length > 2) {
        setCurrentLanguage(sp[2]);
      }
    }
  }, []);

  useEffect(() => {
    const resetTranslation = async () => {
      const cookies = parseCookies();
      const existingLanguageCookieValue = cookies[COOKIE_NAME];
      
      if (existingLanguageCookieValue) {
        const sp = existingLanguageCookieValue.split('/');
        if (sp.length > 2) {
          const lang = sp[2];
          await new Promise(resolve => setTimeout(resolve, 300));
          const selectElement = document.querySelector('.goog-te-combo') as HTMLSelectElement;
          if (selectElement) {
            selectElement.value = lang;
            selectElement.dispatchEvent(new Event('change'));
          }
        }
      }
    };

    resetTranslation();
  }, [pathname]);

  const handleLanguageSwitch = async (lang: string, close: () => void) => {
    if (isTranslating) return;

    try {
      setIsTranslating(true);
      const currentURL = window.location.href;
      
      setLanguageCookie(lang);

      await new Promise(resolve => setTimeout(resolve, 100));
      close();

      if (window.location.href === currentURL) {
        window.location.reload();
      } else {
        router.refresh();
      }
    } catch (error) {
      console.error('Error switching language:', error);
    } finally {
      setIsTranslating(false);
    }
  };

  const renderLang = (close: () => void) => {
    return (
      <div className="grid gap-8 lg:grid-cols-2">
        {headerLanguage.map((item) => (
          <div
            key={item.id}
            onClick={() => handleLanguageSwitch(item.languageCode, close)}
            className={`flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${
              currentLanguage === item.languageCode || 
              (!currentLanguage && item.active) 
                ? "bg-gray-100 dark:bg-gray-700" 
                : "opacity-80"
            } ${isTranslating ? 'cursor-wait' : 'cursor-pointer'}`}
          >
            <div className="notranslate flex items-center">
              <span className="mr-2">{item.emoji}</span>
              <p className="text-sm font-medium">{item.name}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderCurr = (close: () => void) => {
    return (
      <div className="grid gap-7 lg:grid-cols-2">
        {headerCurrency.map((item, index) => (
          <div
            key={index}
            onClick={() => close()}
            className={`flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${
              item.active ? "bg-gray-100 dark:bg-gray-700" : "opacity-80"
            }`}
          >
            <item.icon className="w-[18px] h-[18px]" />
            <p className="ml-2 text-sm font-medium">{item.name}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Popover className={`LangDropdown relative ${className}`}>
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`
              ${open ? "" : "text-opacity-80"}
              group self-center h-10 sm:h-12 px-3 py-1.5 inline-flex items-center text-sm text-gray-800 dark:text-neutral-200 font-medium hover:text-opacity-100 focus:outline-none
            `}
          >
            <GlobeAltIcon className="w-5 h-5 opacity-80" />
            <span className="mx-1">/</span>
            <BanknotesIcon className="w-5 h-5 opacity-80" />
            <ChevronDownIcon
              className={`${open ? "-rotate-180" : "text-opacity-70"}
                ml-1 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className={`absolute z-20 ${panelClassName}`}>
              <div className="p-3 sm:p-6 rounded-2xl bg-white dark:bg-neutral-800 shadow-lg ring-1 ring-black ring-opacity-5">
                <Tab.Group>
                  <Tab.List className="flex space-x-1 rounded-full bg-gray-100 dark:bg-slate-700 p-1">
                    {["Language", "Currency"].map((category) => (
                      <Tab
                        key={category}
                        className={({ selected }) =>
                          classNames(
                            "w-full rounded-full py-2 text-sm font-medium leading-5 text-gray-700",
                            "focus:outline-none focus:ring-0",
                            selected
                              ? "bg-white shadow dark:bg-neutral-700"
                              : "text-gray-700 dark:text-slate-300 hover:bg-white/70 dark:hover:bg-slate-900/40"
                          )
                        }
                      >
                        {category}
                      </Tab>
                    ))}
                  </Tab.List>
                  
                  <Tab.Panels className="mt-5">
                    <Tab.Panel className="rounded-xl p-3 focus:outline-none focus:ring-0">
                      {renderLang(close)}
                    </Tab.Panel>
                    <Tab.Panel className="rounded-xl p-3 focus:outline-none focus:ring-0">
                      {renderCurr(close)}
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default LangDropdown;